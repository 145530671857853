/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

body {
  background-color: white;
  margin: 0;
  display: flex;

  /* This centers our sketch horizontally. */
  justify-content: center;

  /* This centers our sketch vertically. */
  align-items: center;
  overflow: hidden;
}


#debug-view{
    position: absolute;
    /*bottom: 0;*/
    /*right: 0;*/
    /*z-index: ;*/
    background-color: rgba(0,0,0,0.5);
    color: white;
    opacity: 0.0;
    padding: 10px;
    font-family: monospace;
    font-size: 12px;
    /*width:700px;*/
    /*height:700px;*/
    /*transform: scale(0.5);*/
}

/*.dg.ac {*/
/*    z-index:10;*/
/*}*/

#debug-info{
    position: absolute;
    left: 0;
    top: 0;
    font-family: monospace;
    font-size: 10px;

  /*transform: scale(0.5);*/
}
#sketch {
    /*display: none;*/
    z-index: -10;
}
#app{
    /*position: absolute;*/
    /*left:0;top:0;*/

}